<template>
    <div class="section_content w-100" v-if="modelValue && productTemplateLoader">
        <quote-loader />
    </div>
    <Form v-else-if="modelValue" v-slot="{ errors }" @submit="handleSubmit" @invalid-submit="handleFormInvalidSubmit" id="product-template-form" ref="template-form" class="w-100">
        <div class="section_content w-100">
            <div class="section_header">
                <button type="button" class="change_template pointer" @click="closeForm()">
                    <i class="fas fa-reply-all"></i> Choose Template
                </button>
                <ul class="btn_list pointer">
                    <li v-if="toggleAll" @click="openAllSection()">Open All</li>
                    <li v-else @click="closeAllSection()">Close All</li>
                </ul>
            </div>
            <div class="section_header mt-4">
                <h2>Page Settings</h2>
                <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
            </div>
            <div class="color_container mt-1 toggle_content">
                <div class="setting_wpr">
                    <div class="form_grp mb-2">
                        <div class="group_item">
                            <label class="input_label">Name</label>
                            <div class="field_wpr" :class="{ 'has-error': errors.name }">
                                <Field autocomplete="off" type="text" name="page_name" v-model="form.name" placeholder="ex: New Page" rules="required" />
                            </div>
                            <ErrorMessage name="name" class="text-danger" />
                        </div>
                    </div>
                    <div class="form_grp mb-5">
                        <div class="group_item">
                            <label class="input_label">Checkout page url slug</label>
                            <div class="field_wpr" :class="{ 'has-error': errors.slug }">
                                <div class="basic_slug">{{ companySettings.subdomain }}.{{ env.VUE_APP_CHECKOUT_URL }}/</div>
                                <Field autocomplete="off" type="text" name="slug" v-model="form.slug" placeholder="ex: new-product" label="page url" rules="required|lowercase" />
                            </div>
                            <ErrorMessage name="slug" class="text-danger" />
                        </div>
                    </div>
                    <div class="edit_section mb-5">
                        <label for="msg_tst_mode" class="switch_option capsule_btn">
                            <h5>Add message/redirect on test mode?</h5>
                            <input type="checkbox" id="msg_tst_mode" v-model="form.has_test_mode_redirect" :true-value="1" :false-value="0" hidden>
                            <div><span></span></div>
                        </label>
                        <div v-if="form.has_test_mode_redirect" class="mt-3">
                            <label for="msg_tst_mode_url" class="switch_option capsule_btn pb-4">
                                <h5>Other URL</h5>
                                <input type="radio" id="msg_tst_mode_url" v-model="form.test_mode_redirect.redirect_type" :value="1" hidden>
                                <div><span></span></div>
                            </label>
                            <label for="msg_tst_mode_text" class="switch_option capsule_btn pb-4">
                                <h5>Sorry Page</h5>
                                <input type="radio" id="msg_tst_mode_text" v-model="form.test_mode_redirect.redirect_type" :value="2" hidden>
                                <div><span></span></div>
                            </label>
                            <div class="form_grp" v-if="form.test_mode_redirect.redirect_type == 1">
                                <div class="group_item">
                                    <label class="input_label">Enter URL</label>
                                    <div class="field_wpr has_prefix" :class="{ 'has-error': errors.redirect_url }">
                                        <Field autocomplete="off" type="text" name="redirect_url" v-model="form.test_mode_redirect.redirect_url" placeholder="ex: https://yourdomain.com" label="redirect url" rules="required|url" />
                                        <span class="prefix">URL</span>
                                    </div>
                                    <ErrorMessage name="redirect_url" class="text-danger" />
                                    <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                </div>
                            </div>
                            <div class="form_grp" v-else>
                                <div class="group_item">
                                    <label class="input_label">Page Text</label>
                                    <redactor v-model="form.test_mode_redirect.sorry_content" :default-value="testModeRedirectDefaultSorryContent" :default-value-watcher="sorryDefaultValueWatcher" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section_content w-100">
            <div class="section_header">
                <h2>Page Styling</h2>
                <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
            </div>
            <div class="color_container mt-1  toggle_content">
                <div class="setting_wpr mt-4">
                    <div class="container_type" vvvvif="form.type != 3 && form.type != 4">
                        <ul class="type_list">
                            <li>
                                <label for="narrow">
                                    <input type="radio" name="" id="narrow" :value="1" v-model="form.page_styling" hidden>
                                    <span><i class="fas fa-check"></i></span>
                                    <div class="box">
                                        <img src="@/assets/images/narrow.svg" alt=""><h4>Narrow Container</h4>
                                    </div>
                                </label>
                            </li>
                            <li>
                                <label for="wide">
                                    <input type="radio" name="" id="wide" :value="0" v-model="form.page_styling" hidden>
                                    <span><i class="fas fa-check"></i></span>
                                    <div class="box">
                                        <img src="@/assets/images/wide.svg" alt=""><h4>Regular Container</h4>
                                    </div>
                                </label>
                            </li>
                        </ul>
                    </div>
                    <div class="edit_section mb-5">
                        <label for="countdown_timer" class="switch_option capsule_btn">
                            <h5>Show Countdown Timer?</h5>
                            <input type="checkbox" id="countdown_timer" v-model="form.has_countdown_timer" :true-value="1" :false-value="0" hidden>
                            <div><span></span></div>
                        </label>
                        <div v-if="form.has_countdown_timer" class="mt-3">
                            <div class="form_grp mb-2">
                                <div class="group_item">
                                    <label class="input_label">Set Expiry Date</label>
                                    <div class="field_wpr">
                                        <datepicker v-model="form.countdown_timer.expiry" auto-apply placeholder="MM/DD/YYYY" format="MM/dd/yyyy"></datepicker>
                                    </div>
                                </div>
                            </div>
                            <div class="form_grp mb-2">
                                <div class="group_item">
                                    <label class="input_label">Warning Headline <a class="reset-default" @click="form.countdown_timer.headline = defaultSettings.countdown_timer.headline">Reset to default</a></label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.ct_warning_headline }">
                                        <Field autocomplete="off" type="text" name="ct_warning_headline" v-model="form.countdown_timer.headline" placeholder="ex: This offer expires soon!" />
                                    </div>
                                    <ErrorMessage name="ct_warning_headline" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp mb-2">
                                <div class="group_item">
                                    <label class="input_label">Warning Headline Color <a class="reset-default" @click="form.countdown_timer.text_color = defaultSettings.countdown_timer.text_color">Reset to default</a></label>
                                    <Field autocomplete="off" name="ct_healine_txt_clr" v-model="form.countdown_timer.text_color" type="text">
                                        <color-picker v-model="form.countdown_timer.text_color" :classes="{ 'has-error': errors.ct_healine_txt_clr }"  :default-color="defaultSettings.countdown_timer.text_color" />
                                    </Field>
                                    <ErrorMessage name="ct_healine_txt_clr" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp mb-2">
                                <div class="group_item">
                                    <label for="countdown_timer_expire" class="switch_option capsule_btn">
                                        <h5>When Countdown Expire</h5>
                                        <input type="checkbox" id="countdown_timer_expire" v-model="form.has_countdown_expire_url" :true-value="1" :false-value="0" hidden>
                                        <div><span></span></div>
                                    </label>
                                    <div v-if="form.has_countdown_expire_url">
                                        <div class="field_wpr has_prefix" :class="{ 'has-error': errors.ct_expire_url }">
                                            <Field autocomplete="off" type="text" name="ct_expire_url" v-model="form.countdown_timer.expire_url" placeholder="ex: https://yourdomain.com" rules="required|url" label="expire url" />
                                            <span class="prefix">URL</span>
                                        </div>
                                        <ErrorMessage name="ct_expire_url" class="text-danger" />
                                        <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                    </div>
                                </div>
                            </div>
                            <div class="form_grp mb-2">
                                <div class="group_item">
                                    <label class="input_label">Timer Bar Color <a class="reset-default" @click="form.countdown_timer.bg_color = defaultSettings.countdown_timer.bg_color">Reset to default</a></label>
                                    <Field autocomplete="off" name="ct_bar_clr" v-model="form.countdown_timer.bg_color" type="text">
                                        <color-picker v-model="form.countdown_timer.bg_color" :classes="{ 'has-error': errors.ct_bar_clr }" :default-color="defaultSettings.countdown_timer.bg_color" />
                                    </Field>
                                    <ErrorMessage name="ct_bar_clr" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp mb-2">
                                <div class="group_item">
                                    <label class="input_label">Countdown Box Color <a class="reset-default" @click="form.countdown_timer.timer_box_color = defaultSettings.countdown_timer.timer_box_color">Reset to default</a></label>
                                    <Field autocomplete="off" name="ct_timer_box_clr" v-model="form.countdown_timer.timer_box_color" type="text">
                                        <color-picker v-model="form.countdown_timer.timer_box_color" :classes="{ 'has-error': errors.ct_timer_box_clr }" :default-color="defaultSettings.countdown_timer.timer_box_color" />
                                    </Field>
                                    <ErrorMessage name="ct_timer_box_clr" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp mb-2">
                                <div class="group_item">
                                    <label class="input_label">Countdown Text Color <a class="reset-default" @click="form.countdown_timer.timer_box_text_color = defaultSettings.countdown_timer.timer_box_text_color">Reset to default</a></label>
                                    <Field autocomplete="off" name="ct_timer_txt_clr" v-model="form.countdown_timer.timer_box_text_color" type="text">
                                        <color-picker v-model="form.countdown_timer.timer_box_text_color" :classes="{ 'has-error': errors.ct_timer_txt_clr }" :default-color="defaultSettings.countdown_timer.timer_box_text_color" />
                                    </Field>
                                    <ErrorMessage name="ct_timer_txt_clr" class="text-danger" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="edit_section mb-5">
                        <label for="has_logo" class="switch_option capsule_btn">
                            <h5>Company Logo?</h5>
                            <input type="checkbox" id="has_logo" v-model="form.has_logo" :true-value="1" :false-value="0" hidden>
                            <div><span></span></div>
                        </label>
                        <div v-if="form.has_logo" class="mt-3">
                            <h3 class="sub_header">Logo</h3>
                            <image-library v-model="form.logo" image-type="public-logo" upload-text="logo" />
                        </div>
                    </div>
                    <div class="edit_section mb-5">
                        <label for="support_mail" class="switch_option capsule_btn">
                            <h5>Support Email</h5>
                            <input type="checkbox" id="support_mail" v-model="form.has_support_email" :true-value="1" :false-value="0" hidden>
                            <div><span></span></div>
                        </label>
                        <div v-if="form.has_support_email" class="mt-3">
                            <div class="form_grp mt-4">
                                <div class="group_item">
                                    <label class="input_label">Email Text Color <a class="reset-default" @click="form.support_email.color = defaultSettings.support_email.color">Reset to default</a></label>
                                    <Field autocomplete="off" name="support_mail_txt_clr" v-model="form.support_email.color" type="text">
                                        <color-picker v-model="form.support_email.color" :classes="{ 'has-error': errors.support_mail_txt_clr }" :default-color="defaultSettings.support_email.color" />
                                    </Field>
                                    <ErrorMessage name="support_mail_txt_clr" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Support Email</label>
                                    <div class="field_wpr has_prefix" :class="{ 'has-error': errors.support_mail_id }">
                                        <Field autocomplete="off" type="text" name="support_mail_id" v-model="form.support_email.text" placeholder="ex: Need Help? help@superfitcoaching.com" />
                                        <span class="prefix"><i class="fas fa-envelope"></i></span>
                                    </div>
                                    <ErrorMessage name="support_mail_id" class="text-danger" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="edit_section mb-5">
                        <div>
                            <div class="form_grp mt-2">
                                <div class="group_item">
                                    <label class="input_label">Cover Background Color <a class="reset-default" @click="form.cover.bg_color = defaultSettings.cover.bg_color">Reset to default</a></label>
                                    <Field autocomplete="off" name="page_cover_bg_clr" v-model="form.cover.bg_color" type="text">
                                        <color-picker autocomplete="off" v-model="form.cover.bg_color" :classes="{ 'has-error': errors.page_cover_bg_clr }" :default-color="defaultSettings.cover.bg_color" />
                                    </Field>
                                    <ErrorMessage name="page_cover_bg_clr" class="text-danger" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="edit_section mb-5" v-if="form.type == 2 || form.type == 4 || form.type == 6">
                        <label for="has_hero_image" class="switch_option capsule_btn">
                            <h5>User hero image?</h5>
                            <input type="checkbox" id="has_hero_image" v-model="form.cover.has_cover" :true-value="1" :false-value="0" hidden>
                            <div><span></span></div>
                        </label>
                        <div v-if="form.cover.has_cover" class="mt-3">
                            <h3 class="sub_header">Hero Image</h3>
                            <image-library v-model="form.cover.cover_image" image-type="checkout-cover" upload-text="cover" />
                        </div>
                    </div>
                    <div class="edit_section mb-5" v-if="form.type == 1 || form.type == 3 || form.type == 5">
                        <label for="has_headline" class="switch_option capsule_btn">
                            <h5>Show Headline?</h5>
                            <input type="checkbox" id="has_headline" v-model="form.has_headline" :true-value="1" :false-value="0" hidden>
                            <div><span></span></div>
                        </label>
                        <div v-if="form.has_headline" class="mt-3">
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Headline <a class="reset-default" @click="form.headline.text = defaultSettings.headline.text; form.headline.color = defaultSettings.headline.color">Reset to default</a></label>
                                    <div class="combo_fld">
                                        <div class="field_wpr" :class="{ 'has-error': errors.headline }">
                                            <Field autocomplete="off" type="text" name="headline" v-model="form.headline.text" placeholder="ex: GET THIS AMAZING FITNESS PROGRAM" />
                                        </div>
                                        <ErrorMessage name="headline" class="text-danger" />
                                        <div class="fld_clr">
                                            <color-picker v-model="form.headline.color" :classes="{ 'has-error': errors.headline_txt_clr }" :default-color="defaultSettings.headline.bg_color" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="edit_section mb-5" v-if="form.type == 1 || form.type == 3 || form.type == 5">
                        <label for="has_sub_headline" class="switch_option capsule_btn">
                            <h5>Show Sub Headline?</h5>
                            <input type="checkbox" id="has_sub_headline" v-model="form.has_sub_headline" :true-value="1" :false-value="0" hidden>
                            <div><span></span></div>
                        </label>
                        <div v-if="form.has_sub_headline" class="mt-3">
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Sub Headline <a class="reset-default" @click="form.sub_headline.text = defaultSettings.sub_headline.text; form.sub_headline.color = defaultSettings.sub_headline.color">Reset to default</a></label>
                                    <div class="combo_fld">
                                        <div class="field_wpr" :class="{ 'has-error': errors.sub_headline }">
                                            <Field autocomplete="off" type="text" name="sub_headline" v-model="form.sub_headline.text" placeholder="ex: Space is limited so sign up today!" />
                                        </div>
                                        <ErrorMessage name="sub_headline" class="text-danger" />
                                        <div class="fld_clr">
                                            <color-picker v-model="form.sub_headline.color" :classes="{ 'has-error': errors.sub_headline_txt_clr }" :default-color="defaultSettings.sub_headline.bg_color" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section_content w-100">
            <div class="section_header">
                <h2>Required Fields</h2>
                <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
            </div>
            <div class="color_container mt-1 toggle_content">
                <div class="setting_wpr mt-4">
                    <div class="edit_section mb-5">
                        <label for="contact_info" class="switch_option capsule_btn">
                            <h5>Contact Information?</h5>
                            <input type="checkbox" id="contact_info" v-model="form.has_contact_info" :true-value="1" :false-value="0" disabled hidden>
                            <div><span></span></div>
                        </label>
                        <ul class="req_flds" v-if="form.has_contact_info">
                            <li v-for="(field, f) of form.contact_info_fields" :key="f">
                                <h4>{{ field.label }}</h4>
                                <div class="actions" :class="{'disable' : !field.editable}">
                                    <label class="checkbox" :for="`show_ct_fld_${f}`">
                                        <input type="checkbox" name="" :id="`show_ct_fld_${f}`" v-model="field.enabled" :true-value="1" :false-value="0" @change="handleInfoShow(field)" hidden>
                                        <span><i class="fas fa-check"></i></span><p> Show</p>
                                    </label>
                                    <label class="checkbox" :for="`show_ct_req_${f}`">
                                        <input type="checkbox" name="" :id="`show_ct_req_${f}`" v-model="field.required" :true-value="1" :false-value="0" @change="handleInfoRequired(field)" hidden>
                                        <span><i class="fas fa-check"></i></span><p> Required</p>
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="edit_section mb-5">
                        <label for="billing_info" class="switch_option capsule_btn">
                            <h5>Billing Information?</h5>
                            <input type="checkbox" id="billing_info" v-model="form.has_billing_info" :true-value="1" :false-value="0" hidden>
                            <div><span></span></div>
                        </label>
                        <ul class="req_flds" v-if="form.has_billing_info">
                            <li v-for="(field, f) of form.billing_info_fields" :key="f">
                                <h4>{{ field.label }}</h4>
                                <div class="actions">
                                    <label class="checkbox" :for="`show_bl_fld_${f}`">
                                        <input type="checkbox" name="" :id="`show_bl_fld_${f}`" v-model="field.enabled" @change="handleInfoShow(field)" :true-value="1" :false-value="0" hidden>
                                        <span><i class="fas fa-check"></i></span><p> Show</p>
                                    </label>
                                    <label class="checkbox" :for="`show_bl_r_fld_${f}`">
                                        <input type="checkbox" name="" :id="`show_bl_r_fld_${f}`" v-model="field.required" @change="handleInfoRequired(field)" :true-value="1" :false-value="0" hidden>
                                        <span><i class="fas fa-check"></i></span><p> Required</p>
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="edit_section mb-5">
                        <label for="shipping_info" class="switch_option capsule_btn">
                            <h5>Shipping Information?</h5>
                            <input type="checkbox" id="shipping_info" v-model="form.has_shipping_info" :true-value="1" :false-value="0" hidden>
                            <div><span></span></div>
                        </label>
                        <div  v-if="form.has_shipping_info">
                            <ul class="req_flds">
                                <li v-for="(field, f) of form.shipping_info_fields" :key="f">
                                    <h4>{{ field.label }}</h4>
                                    <div class="actions">
                                        <label class="checkbox" :for="`show_sp_fld_${f}`">
                                            <input type="checkbox" name="" :id="`show_sp_fld_${f}`" v-model="field.enabled" @change="handleInfoShow(field)" :true-value="1" :false-value="0" hidden>
                                            <span><i class="fas fa-check"></i></span><p> Show</p>
                                        </label>
                                        <label class="checkbox" :for="`show_sp_r_fld_${f}`">
                                            <input type="checkbox" name="" :id="`show_sp_r_fld_${f}`" v-model="field.required" @change="handleInfoRequired(field)" :true-value="1" :false-value="0" hidden>
                                            <span><i class="fas fa-check"></i></span><p> Required</p>
                                        </label>
                                    </div>
                                </li>
                            </ul>
                            <label for="same_as_billing" class="switch_option capsule_btn mb-2" v-if="form.has_billing_info">
                                <h5>Same as billing information</h5>
                                <input type="checkbox" id="same_as_billing" v-model="form.has_same_as_billing_info" :true-value="1" :false-value="0" hidden>
                                <div><span></span></div>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section_content w-100">
            <div class="section_header">
                <h2>Payment Details</h2>
                <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
            </div>
            <div class="color_container mt-1 toggle_content">
                <div class="setting_wpr mt-4">
                    <div class="edit_section mb-5">
                        <div class="form_grp mb-2">
                            <div class="group_item">
                                <label class="input_label">Background Color <a class="reset-default" @click="form.payment_details.bg_color = defaultSettings.payment_details.bg_color">Reset to default</a></label>
                                <Field autocomplete="off" name="payment_bg_clr" v-model="form.payment_details.bg_color" type="text">
                                    <color-picker autocomplete="off" v-model="form.payment_details.bg_color" :classes="{ 'has-error': errors.payment_bg_clr }" :default-color="defaultSettings.payment_details.bg_color" />
                                </Field>
                                <ErrorMessage name="payment_bg_clr" class="text-danger" />
                            </div>
                        </div>
                        <label for="security_box" class="switch_option capsule_btn">
                            <h5>Show Security Box?</h5>
                            <input type="checkbox" id="security_box" v-model="form.payment_details.has_security_box" :true-value="1" :false-value="0" hidden>
                            <div><span></span></div>
                        </label>
                        <div v-if="form.payment_details.has_security_box" class="mt-3">
                            <div class="form_grp mb-2">
                                <div class="group_item">
                                    <label class="input_label">Security Headline <a class="reset-default" @click="form.payment_details.headline = defaultSettings.payment_details.headline">Reset to default</a></label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.security_headline }">
                                        <Field autocomplete="off" type="text" name="security_headline" v-model="form.payment_details.headline" placeholder="ex: Security is our top priority!" />
                                    </div>
                                    <ErrorMessage name="security_headline" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp mb-2">
                                <div class="group_item">
                                    <label class="input_label">Security Text <a class="reset-default" @click="form.payment_details.text = defaultSettings.payment_details.text">Reset to default</a></label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.security_txt }">
                                        <textarea name="security_txt" v-model="form.payment_details.text" placeholder="ex: Security text goes here..."></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="form_grp mb-2">
                                <div class="group_item">
                                    <label class="input_label">Icon Color <a class="reset-default" @click="form.payment_details.icon_color = defaultSettings.payment_details.icon_color">Reset to default</a></label>
                                    <Field autocomplete="off" name="security_icon_bg_clr" v-model="form.payment_details.icon_color" type="text">
                                        <color-picker autocomplete="off" v-model="form.payment_details.icon_color" :classes="{ 'has-error': errors.security_icon_bg_clr }" :default-color="defaultSettings.payment_details.icon_color" />
                                    </Field>
                                    <ErrorMessage name="security_icon_bg_clr" class="text-danger" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="edit_section mb-5">
                        <label for="product_bump" class="switch_option capsule_btn">
                            <h5>Show Order Bump?</h5>
                            <input type="checkbox" id="product_bump" v-model="form.has_order_bump" :true-value="1" :false-value="0" @change="handleShowOrderBump($event)" hidden>
                            <div><span></span></div>
                        </label>
                        <div v-if="form.has_order_bump" class="mt-2">
                            <div class="form_grp mb-2">
                                <div class="group_item">
                                    <label class="input_label">Order bump acceptance text <a class="reset-default" @click="form.order_bump.acceptance = defaultSettings.order_bump.acceptance">Reset to default</a></label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.product_bump_acceptance_txt }">
                                        <Field autocomplete="off" type="text" name="product_bump_acceptance_txt" v-model="form.order_bump.acceptance" placeholder="ex: Acceptance Text goeas here..." />
                                    </div>
                                    <ErrorMessage name="product_bump_acceptance_txt" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp mb-2">
                                <div class="group_item">
                                    <label class="input_label">Order bump brand color <a class="reset-default" @click="form.order_bump.brand_color = defaultSettings.order_bump.brand_color">Reset to default</a></label>
                                    <Field autocomplete="off" name="product_bump_box_clr" v-model="form.order_bump.brand_color" type="text">
                                        <color-picker autocomplete="off" v-model="form.order_bump.brand_color" :classes="{ 'has-error': errors.product_bump_box_clr }" :default-color="defaultSettings.order_bump.brand_color" />
                                    </Field>
                                    <ErrorMessage name="product_bump_box_clr" class="text-danger" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="edit_section mb-5">
                        <label for="discount_coupons" class="switch_option capsule_btn">
                            <h5>Accept Coupons?</h5>
                            <input type="checkbox" id="discount_coupons" v-model="form.accept_coupon" :true-value="1" :false-value="0" @change="handleShowAcceptCoupon($event)" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                    <div class="edit_section mb-5">
                        <label for="purchase_terms" class="switch_option capsule_btn">
                            <h5>Accept Terms?</h5>
                            <input type="checkbox" id="purchase_terms" v-model="form.accept_term" :true-value="1" :false-value="0" hidden>
                            <div><span></span></div>
                        </label>
                        <div v-if="form.accept_term" class="mt-3">
                            <div class="form_grp mb-2">
                                <div class="group_item">
                                    <label class="input_label">Accept terms text color <a class="reset-default" @click="form.terms.text_color = defaultSettings.terms.text_color">Reset to default</a></label>
                                    <Field autocomplete="off" name="text_color" v-model="form.terms.text_color" type="text">
                                        <color-picker autocomplete="off" v-model="form.terms.text_color" :classes="{ 'has-error': errors.text_color }" :default-color="defaultSettings.terms.text_color" />
                                    </Field>
                                    <ErrorMessage name="text_color" class="text-danger" />
                                </div>
                            </div>
                            <label for="purchase_terms_url" class="switch_option capsule_btn pb-4">
                                <h5>Outbound Link</h5>
                                <input type="radio" id="purchase_terms_url" v-model="form.terms.type" :value="1" hidden>
                                <div><span></span></div>
                            </label>
                            <label for="purchase_terms_pop" class="switch_option capsule_btn pb-4">
                                <h5>Use Popup</h5>
                                <input type="radio" id="purchase_terms_pop" v-model="form.terms.type" :value="2" hidden>
                                <div><span></span></div>
                            </label>
                            <div class="form_grp" v-if="form.terms.type == 1">
                                <div class="group_item">
                                    <label class="input_label">Terms and Conditions URL</label>
                                    <div class="field_wpr has_prefix" :class="{ 'has-error': errors.purchase_terms_url }">
                                        <Field autocomplete="off" type="text" name="purchase_terms_url" v-model="form.terms.redirect_url" rules="url" placeholder="ex: mywebsite.com" label="terms and conditions" />
                                        <span class="prefix">URL</span>
                                    </div>
                                    <ErrorMessage name="purchase_terms_url" class="text-danger" />
                                    <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                </div>
                            </div>
                            <div class="form_grp" v-else>
                                <div class="group_item">
                                    <label class="input_label">Terms and Conditions text</label>
                                    <redactor v-model="form.terms.popup_text" :default-value="termsDefaultPopupText" :default-value-watcher="termsDefaultValueWatcher" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <h3 class="sub_header">Payment Button</h3>
                    <div class="edit_section mb-5">
                        <div class="form_grp mb-2">
                            <div class="group_item">
                                <label class="input_label">Button Text (Limit to 25 characters) <a class="reset-default" @click="form.payment_button.text = defaultSettings.payment_button.text; form.payment_button.text_color = defaultSettings.payment_button.text_color">Reset to default</a></label>
                                <div class="combo_fld">
                                    <div class="field_wpr has_prefix" :class="{ 'has-error': errors.buy_btn_txt }">
                                        <Field autocomplete="off" type="text" name="buy_btn_txt" v-model="form.payment_button.text" placeholder="ex: Place Order" label="button text" rules="required" />
                                        <span class="prefix"><i class="fas fa-hand-pointer"></i></span>
                                    </div>
                                    <ErrorMessage name="buy_btn_txt" class="text-danger" />
                                    <div class="fld_clr">
                                        <color-picker autocomplete="off" v-model="form.payment_button.text_color" :classes="{ 'has-error': errors.buy_btn_txt_clr }" :default-color="defaultSettings.payment_button.text_color" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Button Color <a class="reset-default" @click="form.payment_button.bg_color = defaultSettings.payment_button.bg_color">Reset to default</a></label>
                                <Field autocomplete="off" name="buy_btn_clr" v-model="form.buy_btn_clr" type="text">
                                    <color-picker autocomplete="off" v-model="form.payment_button.bg_color" :classes="{ 'has-error': errors.buy_btn_clr }" :default-color="defaultSettings.payment_button.bg_color" />
                                </Field>
                                <ErrorMessage name="buy_btn_clr" class="text-danger" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section_content w-100" v-if="form.type == 3 || form.type == 4">
            <div class="section_header">
                <h2>Sidebar</h2>
                <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
            </div>
            <div class="color_container mt-1 toggle_content">
                <div class="setting_wpr mt-4">
                    <div class="edit_section mb-5">
                        <label for="checkout_sidebar" class="switch_option capsule_btn">
                            <h5>Sidebar</h5>
                            <input type="checkbox" id="checkout_sidebar" v-model="form.has_sidebar" :true-value="1" :false-value="0" hidden>
                            <div><span></span></div>
                        </label>
                        <div v-if="form.has_sidebar">
                            <div class="edit_section mt-4 mb-3">
                                <label for="checkout_sidebar_left" class="switch_option capsule_btn pb-4">
                                    <h5>Sidebar Left</h5>
                                    <input type="radio" id="checkout_sidebar_left" v-model="form.sidebar.align" value="left" hidden>
                                    <div><span></span></div>
                                </label>
                                <label for="checkout_sidebar_right" class="switch_option capsule_btn">
                                    <h5>Sidebar Right</h5>
                                    <input type="radio" id="checkout_sidebar_right" v-model="form.sidebar.align" value="right" hidden>
                                    <div><span></span></div>
                                </label>
                            </div>
                            <div class="form_grp mb-2">
                                <div class="group_item">
                                    <label class="input_label">Sidebar Bckground Color <a class="reset-default" @click="form.sidebar.bg_color = defaultSettings.sidebar.bg_color">Reset to default</a></label>
                                    <Field autocomplete="off" name="checkout_sidebar_bg_clr" v-model="form.sidebar.bg_color" type="text">
                                        <color-picker autocomplete="off" v-model="form.sidebar.bg_color" :classes="{ 'has-error': errors.checkout_sidebar_bg_clr }" :default-color="defaultSettings.sidebar.bg_color" />
                                    </Field>
                                    <ErrorMessage name="checkout_sidebar_bg_clr" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp mb-2">
                                <div class="group_item">
                                    <label class="input_label">Sidebar Title Text Color <a class="reset-default" @click="form.sidebar.title_text_color = defaultSettings.sidebar.title_text_color">Reset to default</a></label>
                                    <Field autocomplete="off" name="checkout_sidebar_headline_clr" v-model="form.sidebar.title_text_color" type="text">
                                        <color-picker autocomplete="off" v-model="form.sidebar.title_text_color" :classes="{ 'has-error': errors.checkout_sidebar_headline_clr }" :default-color="defaultSettings.sidebar.title_text_color" />
                                    </Field>
                                    <ErrorMessage name="checkout_sidebar_headline_clr" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp mb-2">
                                <div class="group_item">
                                    <label class="input_label">Sidebar Accent Color <a class="reset-default" @click="form.sidebar.accent_color = defaultSettings.sidebar.accent_color">Reset to default</a></label>
                                    <Field autocomplete="off" name="checkout_sidebar_accent_clr" v-model="form.sidebar.accent_color" type="text">
                                        <color-picker autocomplete="off" v-model="form.sidebar.accent_color" :classes="{ 'has-error': errors.checkout_sidebar_accent_clr }" :default-color="defaultSettings.sidebar.accent_color" />
                                    </Field>
                                    <ErrorMessage name="checkout_sidebar_accent_clr" class="text-danger" />
                                </div>
                            </div>
                            <draggable v-model="form.sidebar_orders" item-key="index" :animation="200" tag="ul" class="drag_list" handle=".handle-cat-drag">
                                <template #item="{element}">
                                    <li>
                                        <h4 @click="accordion($event)" class="capitalize handle-cat-drag">{{ element.type }} <i class="fas fa-chevron-down"></i></h4>
                                        <div class="accordion_area" v-if="element.type == 'product'">
                                            <div class="border-bottom pb-3">
                                                <label for="has_product_info" class="switch_option capsule_btn mt-4 mb-0">
                                                    <h5>Show Product Info?</h5>
                                                    <input type="checkbox" id="has_product_info" v-model="form.sidebar_product.has_product" :true-value="1" :false-value="0" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <div class="form_grp mb-2" v-if="form.sidebar_product.has_product">
                                                    <div class="group_item">
                                                        <label class="input_label">Product Info Headline <a class="reset-default" @click="form.sidebar_product.headline = defaultSettings.sidebar_product.headline">Reset to default</a></label>
                                                        <div class="field_wpr" :class="{ 'has-error': errors.product_info_headline }">
                                                            <Field autocomplete="off" type="text" name="product_info_headline" v-model="form.sidebar_product.headline" placeholder="ex: Headline goeas here.." />
                                                        </div>
                                                        <ErrorMessage name="product_info_headline" class="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="border-bottom pb-3">
                                                <label for="has_product_img" class="switch_option capsule_btn mt-4 mb-0">
                                                    <h5>Show Product Image?</h5>
                                                    <input type="checkbox" id="has_product_img" v-model="form.sidebar_product.has_image" :true-value="1" :false-value="0" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <div class="mb-2" v-if="form.sidebar_product.has_image">
                                                    <h3 class="sub_header mt-2">Image</h3>
                                                    <image-library v-model="form.sidebar_product.image" image-type="checkout-product" upload-text="cover" />
                                                </div>
                                            </div>
                                            <div class="border-bottom pb-3">
                                                <label for="has_product_drop_shadow" class="switch_option capsule_btn mt-4 mb-0">
                                                    <h5>Drop Shadow?</h5>
                                                    <input type="checkbox" id="has_product_drop_shadow" v-model="form.sidebar_product.has_shadow" :true-value="1" :false-value="0" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <div class="form_grp mb-2" v-if="form.sidebar_product.has_shadow">
                                                    <div class="group_item">
                                                        <label class="input_label">Drop Shadow <a class="reset-default" @click="form.sidebar_product.shadow_color = defaultSettings.sidebar_product.shadow_color">Reset to default</a></label>
                                                        <Field autocomplete="off" name="product_drop_shadow" v-model="form.sidebar_product.shadow_color" type="text">
                                                            <color-picker autocomplete="off" v-model="form.sidebar_product.shadow_color" :classes="{ 'has-error': errors.product_drop_shadow }" :default-color="defaultSettings.sidebar_product.shadow_color" />
                                                        </Field>
                                                        <ErrorMessage name="product_drop_shadow" class="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="pb-5">
                                                <label for="has_product_feature_bullets" class="switch_option capsule_btn mt-4 mb-0">
                                                    <h5>Show Feature Bullets?</h5>
                                                    <input type="checkbox" id="has_product_feature_bullets" v-model="form.sidebar_product.has_feature" :true-value="1" :false-value="0" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <div v-if="form.sidebar_product.has_feature" class="bullet_setting_wpr">
                                                    <div class="form_grp mb-2">
                                                        <div class="group_item">
                                                            <label class="input_label">Bullet Color <a class="reset-default" @click="form.sidebar_product.feature_icon_color = defaultSettings.sidebar_product.feature_icon_color">Reset to default</a></label>
                                                            <Field autocomplete="off" name="bullets_clr" v-model="form.sidebar_product.feature_icon_color" type="text">
                                                                <color-picker autocomplete="off" v-model="form.sidebar_product.feature_icon_color" :classes="{ 'has-error': errors.bullets_clr }" :default-color="defaultSettings.sidebar_product.feature_icon_color" />
                                                            </Field>
                                                            <ErrorMessage name="bullets_clr" class="text-danger"/>
                                                        </div>
                                                    </div>
                                                    <div class="bullet_icons">
                                                        <div class="icon" v-for="(icon, i) of featureIcons" :key="i" :class="form.sidebar_product.feature_icon == icon ? 'active' : ''" @click="form.sidebar_product.feature_icon = icon;">
                                                            <i :class="`fas ${icon}`"></i>
                                                        </div>
                                                    </div>
                                                    <draggable v-model="form.sidebar_product_features" item-key="index" :animation="200" tag="ul" class="custom_list mt-4" v-if="form.sidebar_product_features.length">
                                                        <template #item="{element: item, index: i}">
                                                            <li class="move">
                                                                <h5>
                                                                    <i :class="`fas fa-${form.sidebar_product.feature_icon}`" :style="`color:${form.sidebar_product.feature_icon_color}`"></i>
                                                                    <label :contenteditable="productFeatureEditedIndex != null ? 'true' : 'false'" @click="handleEditProductFeatureTitle($event, i)" :class="{'pointer' : !productFeatureEditedIndex}" @input="(ev) => item.title = ev.target.innerText" >
                                                                        {{ item.title }}
                                                                    </label>
                                                                    <span v-if="productFeatureEditedIndex == i" class="save" @click="handleProductFeatureUpdate()">Save</span>
                                                                    <button type="button" class="delete_btn ml-auto" @click="handleProductFeatureDelete(i)"><i class="fas fa-trash-alt"></i></button>
                                                                </h5>
                                                            </li>
                                                        </template>
                                                    </draggable>
                                                    <div class="empty" v-else>No Items Available!</div>
                                                    <a class="add_btn" @click="handleProductFeatureAdd()">+ Add New</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion_area" v-if="element.type == 'proof'">
                                            <label for="has_proof" class="switch_option capsule_btn mt-4 mb-3">
                                                <h5>Show Proof?</h5>
                                                <input type="checkbox" id="has_proof" v-model="form.has_sidebar_proof" :true-value="1" :false-value="0" hidden>
                                                <div><span></span></div>
                                            </label>
                                            <div class="form_grp mb-2" v-if="form.has_sidebar_proof">
                                                <div class="group_item">
                                                    <label class="input_label">Proof Headline  <a class="reset-default" @click="form.proof_headline= defaultSettings.proof_headline">Reset to default</a></label>
                                                    <div class="field_wpr" :class="{ 'has-error': errors.proof_headline }">
                                                        <Field autocomplete="off" type="text" name="proof_headline" v-model="form.proof_headline" placeholder="ex: Headline goeas here.." />
                                                    </div>
                                                    <ErrorMessage name="proof_headline" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="proof_wpr" v-if="form.has_sidebar_proof">
                                                <draggable v-model="form.proofs" :animation="200" item-key="id" tag="ul" class="proof_list" v-if="form.proofs.length">
                                                    <template #item="{element: item, index: i}">
                                                        <li class="proof_card">
                                                            <h3 class="sub_header m-0">
                                                                Proof {{ i + 1 }}
                                                                <div class="sm_switch ml-auto">
                                                                    <label :for="`case_study_proof-${i}`" class="switch_option capsule_btn mb-0 border-0">
                                                                        <h5>Case Study Video</h5>
                                                                        <input type="radio" :id="`case_study_proof-${i}`" v-model="item.type" :value="0" hidden>
                                                                        <div><span></span></div>
                                                                    </label>
                                                                    <label :for="`written_proof-${i}`" class="switch_option capsule_btn">
                                                                        <h5>Written Review</h5>
                                                                        <input type="radio" :id="`written_proof-${i}`" v-model="item.type" :value="1" hidden>
                                                                        <div><span></span></div>
                                                                    </label>
                                                                </div>
                                                                <button type="button" class="delete_btn danger pointer ml-3" @click="handleProofDelete(item.template_id, item.id, i)"><i class="fas fa-trash-alt"></i></button>
                                                            </h3>
                                                            <div class="details" v-if="item.type == 0">
                                                                <div class="form_grp mb-2">
                                                                    <div class="group_item">
                                                                        <label class="input_label">Headline</label>
                                                                        <div class="field_wpr" :class="{ 'has-error': errors[`proof_headline_${i}`] }">
                                                                            <Field autocomplete="off" type="text" :name="`proof_headline_${i}`" v-model="item.headline" placeholder="ex: Headline goeas here.." />
                                                                        </div>
                                                                        <ErrorMessage :name="`proof_headline_${i}`" class="text-danger" />
                                                                    </div>
                                                                </div>
                                                                <div class="form_grp mb-2">
                                                                    <div class="group_item">
                                                                        <label class="input_label">Background Color <a class="reset-default" @click="item.bg_color= defaultSettings.sidebar_product.proof_bg_color">Reset to default</a></label>
                                                                        <Field autocomplete="off" :name="`proof_bg_color_${i}`" v-model="item.bg_color" type="text">
                                                                            <color-picker autocomplete="off" v-model="item.bg_color" :classes="{ 'has-error': errors[`proof_bg_color_${i}`] }" :default-color="defaultSettings.sidebar_product.proof_bg_color" />
                                                                        </Field>
                                                                        <ErrorMessage :name="`proof_bg_color_${i}`" class="text-danger" />
                                                                    </div>
                                                                </div>
                                                                <div class="form_grp mb-2">
                                                                    <div class="group_item">
                                                                        <label class="input_label">Text Color <a class="reset-default" @click="item.text_color = defaultSettings.sidebar_product.proof_text_color">Reset to default</a></label>
                                                                        <Field autocomplete="off" :name="`proof_text_color_${i}`" v-model="item.text_color" type="text">
                                                                            <color-picker v-model="item.text_color" :classes="{ 'has-error': errors[`proof_text_color_${i}`] }" :default-color="defaultSettings.sidebar_product.proof_text_color" />
                                                                        </Field>
                                                                        <ErrorMessage :name="`proof_text_color_${i}`" class="text-danger" />
                                                                    </div>
                                                                </div>
                                                                <label :for="`proof_has_shadow_${i}`" class="switch_option capsule_btn mt-4 mb-2">
                                                                    <h5>Drop Shadow?</h5>
                                                                    <input type="checkbox" :id="`proof_has_shadow_${i}`" v-model="item.has_shadow" :true-value="1" :false-value="0" hidden>
                                                                    <div><span></span></div>
                                                                </label>
                                                                <div class="form_grp mb-2" v-if="item.has_shadow">
                                                                    <div class="group_item">
                                                                        <label class="input_label">Drop Shadow <a class="reset-default" @click="item.shadow_color = defaultSettings.sidebar_product.shadow_color">Reset to default</a></label>
                                                                        <Field autocomplete="off" :name="`proof_shadow_color_${i}`" v-model="item.shadow_color" type="text">
                                                                            <color-picker autocomplete="off" v-model="item.shadow_color" :classes="{ 'has-error': errors[`proof_shadow_color_${i}`] }" :default-color="defaultSettings.sidebar_product.shadow_color" />
                                                                        </Field>
                                                                        <ErrorMessage :name="`proof_shadow_color_${i}`" class="text-danger" />
                                                                    </div>
                                                                </div>
                                                                <div class="form_grp mb-2" >
                                                                    <div class="group_item">
                                                                        <label class="input_label">Video URL (Youtube, Vimeo, Wistia, Loom)</label>
                                                                        <div class="field_wpr has_prefix" :class="{ 'has-error': errors[`proof_url_${i}`] }">
                                                                            <Field autocomplete="off" type="text" :name="`proof_url_${i}`" v-model="item.video_url" placeholder="ex: https://yourdomain.com" label="url" rules="url" />
                                                                            <span class="prefix">URL</span>
                                                                        </div>
                                                                        <ErrorMessage :name="`proof_url_${i}`" class="text-danger" />
                                                                        <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="details" v-else>
                                                                <div class="form_grp mb-2">
                                                                    <div class="group_item">
                                                                        <label class="input_label">Testimonial Text <a class="reset-default" @click="item.testimonial = defaultSettings.sidebar_product.testimonial">Reset to default</a></label>
                                                                        <div class="field_wpr">
                                                                            <textarea :name="`proof_testimonial_${i}`" v-model="item.testimonial" placeholder="ex: Headline goeas here.."></textarea>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="form_grp mb-2">
                                                                    <div class="group_item">
                                                                        <label class="input_label">Name of Person <a class="reset-default" @click="item.person_name = defaultSettings.sidebar_product.person_name">Reset to default</a></label>
                                                                        <div class="field_wpr" :class="{ 'has-error': errors[`proof_person_name_${i}`] }">
                                                                            <Field autocomplete="off" type="text" :name="`proof_person_name_${i}`" v-model="item.person_name" placeholder="ex: John Doe" />
                                                                        </div>
                                                                        <ErrorMessage :name="`proof_person_name_${i}`" class="text-danger" />
                                                                    </div>
                                                                </div>
                                                                <div class="form_grp mb-2">
                                                                    <div class="group_item">
                                                                        <label class="input_label">Title or Location <a class="reset-default" @click="item.title_location = defaultSettings.sidebar_product.title_location">Reset to default</a></label>
                                                                        <div class="field_wpr" :class="{ 'has-error': errors[`proof_title_location_${i}`] }">
                                                                            <Field autocomplete="off" type="text" :name="`proof_title_location_${i}`" v-model="item.title_location" placeholder="ex: Lemont, Pa" />
                                                                        </div>
                                                                        <ErrorMessage :name="`proof_title_location_${i}`" class="text-danger" />
                                                                    </div>
                                                                </div>
                                                                <div class="form_grp mb-2">
                                                                    <div class="group_item">
                                                                        <label class="input_label">Background Color <a class="reset-default" @click="item.bg_color = defaultSettings.sidebar_product.proof_bg_color">Reset to default</a></label>
                                                                        <Field autocomplete="off" :name="`proof_bg_color_${i}`" v-model="item.bg_color" type="text">
                                                                            <color-picker autocomplete="off" v-model="item.bg_color" :classes="{ 'has-error': errors[`proof_bg_color_${i}`] }" />
                                                                        </Field>
                                                                        <ErrorMessage :name="`proof_bg_color_${i}`" class="text-danger" />
                                                                    </div>
                                                                </div>
                                                                <div class="form_grp mb-2">
                                                                    <div class="group_item">
                                                                        <label class="input_label">Text Color  <a class="reset-default" @click="item.text_color = defaultSettings.sidebar_product.proof_text_color">Reset to default</a></label>
                                                                        <Field autocomplete="off" :name="`proof_text_color_${i}`" v-model="item.text_color" type="text">
                                                                            <color-picker v-model="item.text_color" :classes="{ 'has-error': errors[`proof_text_color_${i}`] }" :default-color="defaultSettings.sidebar_product.proof_text_color" />
                                                                        </Field>
                                                                        <ErrorMessage :name="`proof_text_color_${i}`" class="text-danger" />
                                                                    </div>
                                                                </div>
                                                                <div class="border-bottom pb-3">
                                                                    <label :for="`proof-has-image-${i}`" class="switch_option capsule_btn mt-4 mb-0">
                                                                        <h5>Show Image?</h5>
                                                                        <input type="checkbox" :id="`proof-has-image-${i}`" v-model="item.has_image" :true-value="1" :false-value="0" hidden>
                                                                        <div><span></span></div>
                                                                    </label>
                                                                    <div class="mb-2" v-if="item.has_image">
                                                                        <h3 class="sub_header mt-2">Image</h3>
                                                                        <image-library v-model="item.image" image-type="coach-logo" upload-text="logo" />
                                                                    </div>
                                                                </div>
                                                                <div class="border-bottom pb-3">
                                                                    <label :for="`proof_has_shadow_${i}`" class="switch_option capsule_btn mt-4 mb-0">
                                                                        <h5>Drop Shadow?</h5>
                                                                        <input type="checkbox" :id="`proof_has_shadow_${i}`" v-model="item.has_shadow" :true-value="1" :false-value="0" hidden>
                                                                        <div><span></span></div>
                                                                    </label>
                                                                    <div class="form_grp mb-2" v-if="item.has_shadow">
                                                                        <div class="group_item">
                                                                            <label class="input_label">Drop Shadow <a class="reset-default" @click="item.shadow_color = defaultSettings.sidebar_product.shadow_color">Reset to default</a></label>
                                                                            <Field autocomplete="off" :name="`proof_shadow_color_${i}`" v-model="item.shadow_color" type="text">
                                                                                <color-picker autocomplete="off" v-model="item.shadow_color" :classes="{ 'has-error': errors[`proof_shadow_color_${i}`] }" :default-color="defaultSettings.sidebar_product.shadow_color" />
                                                                            </Field>
                                                                            <ErrorMessage :name="`proof_shadow_color_${i}`" class="text-danger" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="pb-3">
                                                                    <label :for="`proof-has-star-${i}`" class="switch_option capsule_btn mt-4 mb-0">
                                                                        <h5>Show 5 stars?</h5>
                                                                        <input type="checkbox" :id="`proof-has-star-${i}`" v-model="item.has_star" :true-value="1" :false-value="0" hidden>
                                                                        <div><span></span></div>
                                                                    </label>
                                                                    <div class="form_grp mb-2" v-if="item.has_star">
                                                                        <div class="group_item">
                                                                            <label class="input_label">Stars color <a class="reset-default" @click="item.star_color = defaultSettings.sidebar_product.star_color">Reset to default</a></label>
                                                                            <Field autocomplete="off" :name="`proof_star_color_${i}`" v-model="item.star_color" type="text">
                                                                                <color-picker autocomplete="off" v-model="item.star_color" :classes="{ 'has-error': errors[`proof_star_color_${i}`] }" :default-color="defaultSettings.sidebar_product.proof_star_color"/>
                                                                            </Field>
                                                                            <ErrorMessage :name="`proof_star_color_${i}`" class="text-danger" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </template>
                                                </draggable>
                                                <div class="empty" v-else>No Items Available!</div>
                                                <a class="add_btn mt-3" @click="handleProofAdd()">+ Add New</a>
                                            </div>
                                        </div>
                                        <div class="accordion_area" v-if="element.type == 'guarantee'">
                                            <div class="border-bottom pb-3">
                                                <label for="has_guarantee" class="switch_option capsule_btn mt-4 mb-0">
                                                    <h5>Show Guarantee?</h5>
                                                    <input type="checkbox" id="has_guarantee" v-model="form.sidebar_guarantee.has_guarantee" :true-value="1" :false-value="0" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <div v-if="form.sidebar_guarantee.has_guarantee">
                                                    <div class="form_grp mb-2">
                                                        <div class="group_item">
                                                            <label class="input_label">Guarantee Headline <a class="reset-default" @click="form.sidebar_guarantee.headline = defaultSettings.sidebar_guarantee.headline">Reset to default</a></label>
                                                            <div class="field_wpr" :class="{ 'has-error': errors.guarantee_headline }">
                                                                <Field autocomplete="off" type="text" name="guarantee_headline" v-model="form.sidebar_guarantee.headline" placeholder="ex: You Are Protected" />
                                                            </div>
                                                            <ErrorMessage name="guarantee_headline" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="form_grp mb-2">
                                                        <div class="group_item">
                                                            <label class="input_label">Guarantee Top Text <a class="reset-default" @click="form.sidebar_guarantee.top_text = defaultSettings.sidebar_guarantee.top_text">Reset to default</a></label>
                                                            <div class="field_wpr">
                                                                <textarea name="guarantee_top_txt" v-model="form.sidebar_guarantee.top_text" placeholder="ex: Our 100% Ironclad Guarantee"></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form_grp mb-2">
                                                        <div class="group_item">
                                                            <label class="input_label">Guarantee Body Text <a class="reset-default" @click="form.sidebar_guarantee.body_text = defaultSettings.sidebar_guarantee.body_text">Reset to default</a></label>
                                                            <div class="field_wpr">
                                                                <textarea name="guarantee_body_text" v-model="form.sidebar_guarantee.body_text" placeholder="Text goes here.."></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form_grp mb-2">
                                                        <div class="group_item">
                                                            <label class="input_label">Guarantee Text Color <a class="reset-default" @click="form.sidebar_guarantee.text_color = defaultSettings.sidebar_guarantee.text_color">Reset to default</a></label>
                                                            <Field autocomplete="off" name="guarantee_txt_clr" v-model="form.sidebar_guarantee.text_color" type="text">
                                                                <color-picker autocomplete="off" v-model="form.sidebar_guarantee.text_color" :classes="{ 'has-error': errors.guarantee_txt_clr }" :default-color="defaultSettings.sidebar_guarantee.text_color" />
                                                            </Field>
                                                            <ErrorMessage name="guarantee_txt_clr" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="form_grp mb-2">
                                                        <div class="group_item">
                                                            <label class="input_label">Guarantee Background Color <a class="reset-default" @click="form.sidebar_guarantee.bg_color = defaultSettings.sidebar_guarantee.bg_color">Reset to default</a></label>
                                                            <Field autocomplete="off" name="guarantee_bg_clr" v-model="form.sidebar_guarantee.bg_color" type="text">
                                                                <color-picker autocomplete="off" v-model="form.sidebar_guarantee.bg_color" :classes="{ 'has-error': errors.guarantee_bg_clr }" :default-color="defaultSettings.sidebar_guarantee.bg_color" />
                                                            </Field>
                                                            <ErrorMessage name="guarantee_bg_clr" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="border-bottom pb-3">
                                                <label for="has_guarantee_icon" class="switch_option capsule_btn mt-4 mb-0">
                                                    <h5>Show Guarantee Icon?</h5>
                                                    <input type="checkbox" id="has_guarantee_icon" v-model="form.sidebar_guarantee.has_icon" :true-value="1" :false-value="0" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <div v-if="form.sidebar_guarantee.has_icon">
                                                    <div class="form_grp mb-2">
                                                        <div class="group_item">
                                                            <label class="input_label">Guarantee Icon Color <a class="reset-default" @click="form.sidebar_guarantee.icon_color = defaultSettings.sidebar_guarantee.icon_color">Reset to default</a></label>
                                                            <Field autocomplete="off" name="guarantee_icon_clr" v-model="form.sidebar_guarantee.icon_color" type="text">
                                                                <color-picker autocomplete="off" v-model="form.sidebar_guarantee.icon_color" :classes="{ 'has-error': errors.guarantee_icon_clr }" :default-color="defaultSettings.sidebar_guarantee.icon_color" />
                                                            </Field>
                                                            <ErrorMessage name="guarantee_icon_clr" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="form_grp mb-2">
                                                        <div class="group_item">
                                                            <label class="input_label">Icon Background Color <a class="reset-default" @click="form.sidebar_guarantee.icon_bg_color = defaultSettings.sidebar_guarantee.icon_bg_color">Reset to default</a></label>
                                                            <Field autocomplete="off" name="guarantee_icon_bg_clr" v-model="form.sidebar_guarantee.icon_bg_color" type="text">
                                                                <color-picker autocomplete="off" v-model="form.sidebar_guarantee.icon_bg_color" :classes="{ 'has-error': errors.guarantee_icon_bg_clr }" :default-color="defaultSettings.sidebar_guarantee.icon_bg_color" />
                                                            </Field>
                                                            <ErrorMessage name="guarantee_icon_bg_clr" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="pb-3">
                                                <label for="has_guarantee_drop_shadow" class="switch_option capsule_btn mt-4 mb-3">
                                                    <h5>Drop Shadow?</h5>
                                                    <input type="checkbox" id="has_guarantee_drop_shadow" v-model="form.sidebar_guarantee.has_shadow" :true-value="1" :false-value="0" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <div class="form_grp mb-2 mt-2" v-if="form.sidebar_guarantee.has_shadow">
                                                    <div class="group_item">
                                                        <label class="input_label">Sidebar Drop Shadow <a class="reset-default" @click="form.sidebar_guarantee.shadow_color = defaultSettings.sidebar_guarantee.shadow_color">Reset to default</a></label>
                                                        <Field autocomplete="off" name="guarantee_drop_shadow" v-model="form.sidebar_guarantee.shadow_color" type="text">
                                                            <color-picker autocomplete="off" v-model="form.sidebar_guarantee.shadow_color" :classes="{ 'has-error': errors.guarantee_drop_shadow }" :default-color="defaultSettings.sidebar_guarantee.shadow_color" />
                                                        </Field>
                                                        <ErrorMessage name="guarantee_drop_shadow" class="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion_area" v-if="element.type == 'security'">
                                            <label for="has_security_seal" class="switch_option capsule_btn mt-4 mb-3">
                                                <h5>Show Security Seal?</h5>
                                                <input type="checkbox" id="has_security_seal" v-model="form.sidebar_security.has_security" :true-value="1" :false-value="0" hidden>
                                                <div><span></span></div>
                                            </label>
                                            <div v-if="form.sidebar_security.has_security">
                                                <div class="form_grp mb-2">
                                                    <div class="group_item">
                                                        <label class="input_label">Security Headline <a class="reset-default" @click="form.sidebar_security.headline = defaultSettings.sidebar_security.headline">Reset to default</a></label>
                                                        <div class="field_wpr" :class="{ 'has-error': errors.security_headline }">
                                                            <Field autocomplete="off" type="text" name="security_headline" v-model="form.sidebar_security.headline" placeholder="ex: Headline goeas here.." />
                                                        </div>
                                                        <ErrorMessage name="security_headline" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="form_grp mb-2">
                                                    <div class="group_item">
                                                        <label class="input_label">Seal Color <a class="reset-default" @click="form.sidebar_security.seal_color = defaultSettings.sidebar_security.seal_color">Reset to default</a></label>
                                                        <Field autocomplete="off" name="seal_clr" v-model="form.sidebar_security.seal_color" type="text">
                                                            <color-picker autocomplete="off" v-model="form.sidebar_security.seal_color" :classes="{ 'has-error': errors.seal_clr }" :default-color="defaultSettings.sidebar_security.seal_color" />
                                                        </Field>
                                                        <ErrorMessage name="seal_clr" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="form_grp mb-2">
                                                    <div class="group_item">
                                                        <label class="input_label">Seal Text Color <a class="reset-default" @click="form.sidebar_security.seal_text_color = defaultSettings.sidebar_security.seal_text_color">Reset to default</a></label>
                                                        <Field autocomplete="off" name="seal_txt_clr" v-model="form.sidebar_security.seal_text_color" type="text">
                                                            <color-picker autocomplete="off" v-model="form.sidebar_security.seal_text_color" :classes="{ 'has-error': errors.seal_txt_clr }" :default-color="defaultSettings.sidebar_security.seal_text_color" />
                                                        </Field>
                                                        <ErrorMessage name="seal_txt_clr" class="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </template>
                            </draggable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button id="template-form-submit-button" style="display: none;">Save Template</button>
    </Form>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    import Draggable from 'vuedraggable'
    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'

    export default {
        name: 'Coupon Form',

        data () {
            return {
                form: {
                    test_mode_redirect: {},
                    countdown_timer: {},
                    support_email: {},
                    cover: {},
                    headline: {},
                    sub_headline: {},
                    payment_details: {},
                    order_bump: {},
                    terms: {},
                    payment_button: {},
                    contact_info_fields: {},
                    billing_info_fields: {},
                    shipping_info_fields: {},
                    sidebar: {},
                    sidebar_product: {},
                    sidebar_product_features: {},
                    sidebar_guarantee: {},
                    sidebar_security: {},
                    sidebar_orders: {},
                    proofs: [],
                    has_order_bump: 0,
                    has_countdown_expire_url: 0,
                },
                featureIcons: [
                    'fa-check',
                    'fa-check-circle',
                    'fa-check-square',
                    'fa-plus',
                    'fa-star',
                    'fa-shield-alt',
                    'fa-heart',
                    'fa-poo-storm',
                    'fa-lightbulb',
                    'fa-play',
                    'fa-play-circle'
                ],
                productFeatureEditedIndex: null,
                termsDefaultValueWatcher: 0,
                sorryDefaultValueWatcher: 0,
                env: {},
                toggleAll: 1,
                termsDefaultPopupText: '',
                testModeRedirectDefaultSorryContent: '',
                resettingForm: false,
                autoSaveTemplate: false,
            };
        },

        props: {
            modelValue: Boolean,
            selectedProduct: Object,
            template: Object,
            oldTemplate: Object,
            templateFormHandler: Function,
            handleNextStep: Function,
            refreshProduct: Function,
            templateAutoSaveWacher: Number,
        },

        emits: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
            Draggable,
        },

        watch: {
            modelValue (value) {
                const vm = this;

                vm.toggleAll = 1;
                vm.productFeatureEditedIndex = null;
                vm.resetForm(value);
            },

            form: {
                handler (form) {
                    const vm = this;

                    vm.templateFormHandler(form);
                },
                deep: true,
            },

            'form.terms.type' (type) {
                const vm = this;

                if (type == 2) {
                    setTimeout(function () {
                        vm.termsDefaultValueWatcher += 1;
                    }, 100);
                }
            },

            'form.has_test_mode_redirect' (val) {
                const vm = this;

                setTimeout(function () {
                    vm.sorryDefaultValueWatcher += 1;
                }, 100);
            },

            'form.test_mode_redirect.redirect_type' (type) {
                const vm = this;

                if (type == 2) {
                    setTimeout(function () {
                        vm.sorryDefaultValueWatcher += 1;
                    }, 100);
                }
            },

            // templateAutoSaveWacher (val) {
            //     const vm = this;
            //     setTimeout(() => {

            //         if (vm.resettingForm) {
            //             vm.autoSaveTemplate = true;
            //         } else {
            //             const params = vm.form;

            //             vm.saveProductTemplate(params).then((result) => {
            //                 if (result) {
            //                     vm.refreshProduct();

            //                     if (vm.template && vm.template.is_default == 1) {
            //                         setTimeout(() => {
            //                             vm.resetForm();
            //                         }, 100);
            //                     }
            //                 }
            //             });
            //         }
            //     }, 500);
            // },

            resettingForm (val) {
                const vm = this;

                if (!val && vm.autoSaveTemplate) {
                    setTimeout(() => {
                        const params = vm.form;

                        vm.saveProductTemplate(params).then((result) => {
                            if (result) {
                                vm.refreshProduct();

                            // if (vm.template && vm.template.is_default == 1) {
                            //     setTimeout(() => {
                            //         vm.resetForm();
                            //     }, 100);
                            // }
                            }
                        });

                        vm.autoSaveTemplate = false;
                    }, 500);
                }
            }
        },

        computed: mapState({
            user: state => state.authModule.user,
            companySettings: state => state.checkoutModule.companySettings,
            defaultSettings: state => state.checkoutModule.defaultProductSettings,
            productTemplateLoader: state => state.checkoutModule.productTemplateLoader,
        }),

        mounted () {
            const vm = this;

            vm.env = process.env;
            vm.productFeatureEditedIndex = null;
            vm.resetForm();
        },

        methods: {
            ...mapActions({
                createCoupon: 'checkoutModule/createCoupon',
                saveProductTemplate: 'checkoutModule/saveProductTemplate',
                getProductTemplate: 'checkoutModule/getProductTemplate',
                deleteProof: 'checkoutModule/deleteProof'
            }),

            resetForm (autosave = false) {
                const vm = this;

                vm.resettingForm                        = true;
                vm.termsDefaultPopupText                = vm.template.terms ? vm.template.terms.popup_text : '';
                vm.testModeRedirectDefaultSorryContent  = vm.template.test_mode_redirect.sorry_content;
                vm.autoSaveTemplate                     = autosave;

                if (vm.template && vm.template.is_default == 1) {
                    vm.getProductTemplate({ product_id: vm.selectedProduct.id, primary_template_id: vm.template.id }).then((template) => {
                        if (template) {
                            vm.form = JSON.parse(JSON.stringify(template));

                            vm.form.slug        = vm.selectedProduct.slug;
                            vm.form.is_default  = vm.template.is_default ? vm.template.is_default : template.is_default;

                            if (!vm.form.product_id) {
                                vm.form.product_id = vm.selectedProduct.id;
                            }

                            if (!vm.form.default_template_id) {
                                vm.form.default_template_id = vm.template.id;
                            }

                            vm.form.has_order_bump = vm.selectedProduct.has_bump ? vm.form.has_order_bump : 0;

                            vm.resetWithOldTemplate();

                            setTimeout(function () {
                                vm.termsDefaultValueWatcher += 1;
                                vm.sorryDefaultValueWatcher += 1;
                            }, 1000);
                        }

                        vm.resettingForm = false;
                    })
                } else {
                    vm.form = JSON.parse(JSON.stringify(vm.template));

                    if (!vm.form.product_id) {
                        vm.form.product_id = vm.selectedProduct.id;
                    }

                    if (!vm.form.default_template_id) {
                        vm.form.default_template_id = vm.template.id;
                    }

                    vm.form.has_order_bump = vm.selectedProduct.has_bump ? vm.form.has_order_bump : 0;

                    vm.resetWithOldTemplate();

                    setTimeout(function () {
                        vm.termsDefaultValueWatcher += 1;
                        vm.sorryDefaultValueWatcher += 1;
                    }, 1000);
                }

                if (vm.form && vm.form.terms && !vm.form.terms.text_color) {
                    vm.form.terms.text_color = vm.oldTemplate && vm.oldTemplate.terms && vm.oldTemplate.terms.text_color ? vm.template.terms.text_color : '#2c3e50';
                }
            },

            resetWithOldTemplate () {
                const vm = this;

                vm.termsDefaultPopupText = vm.oldTemplate && vm.oldTemplate.terms ? vm.oldTemplate.terms.popup_text : '';
                vm.testModeRedirectDefaultSorryContent  = vm.template.test_mode_redirect.sorry_content;

                if (vm.oldTemplate) {
                    vm.form.accept_coupon             = vm.oldTemplate.accept_coupon;
                    vm.form.accept_term               = vm.oldTemplate.accept_term;
                    vm.form.billing_info_fields       = vm.oldTemplate.billing_info_fields;
                    vm.form.contact_info_fields       = vm.oldTemplate.contact_info_fields;
                    vm.form.shipping_info_fields      = vm.oldTemplate.shipping_info_fields;
                    vm.form.countdown_timer           = vm.oldTemplate.countdown_timer;
                    vm.form.cover.bg_color            = vm.oldTemplate.cover.bg_color;
                    vm.form.cover.cover_image         = vm.oldTemplate.cover.cover_image;
                    vm.form.has_billing_info          = vm.oldTemplate.has_billing_info;
                    vm.form.has_contact_info          = vm.oldTemplate.has_contact_info;
                    vm.form.has_countdown_expire_url  = vm.oldTemplate.has_countdown_expire_url;
                    vm.form.has_countdown_timer       = vm.oldTemplate.has_countdown_timer;
                    vm.form.has_headline              = vm.oldTemplate.has_headline;
                    vm.form.has_logo                  = vm.oldTemplate.has_logo;
                    vm.form.has_order_bump            = vm.oldTemplate.has_order_bump;
                    vm.form.has_sale_tax              = vm.oldTemplate.has_sale_tax;
                    vm.form.has_same_as_billing_info  = vm.oldTemplate.has_same_as_billing_info;
                    vm.form.has_shipping_info         = vm.oldTemplate.has_shipping_info;
                    vm.form.has_sub_headline          = vm.oldTemplate.has_sub_headline;
                    vm.form.has_support_email         = vm.oldTemplate.has_support_email;
                    vm.form.has_test_mode_redirect    = vm.oldTemplate.has_test_mode_redirect;
                    vm.form.headline                  = vm.oldTemplate.headline;
                    vm.form.page_styling              = vm.oldTemplate.page_styling;
                    vm.form.payment_button            = vm.oldTemplate.payment_button;
                    vm.form.payment_details           = vm.oldTemplate.payment_details;
                    vm.form.sub_headline              = vm.oldTemplate.sub_headline;
                    vm.form.support_email             = vm.oldTemplate.support_email;
                    vm.form.terms                     = vm.oldTemplate.terms;
                    vm.form.test_mode_redirect        = vm.oldTemplate.test_mode_redirect;
                    vm.form.order_bump                = vm.oldTemplate.order_bump;

                    if (vm.oldTemplate.proofs && vm.oldTemplate.proofs.length) {
                        vm.form.proofs = vm.oldTemplate.proofs;
                    }

                    if (vm.oldTemplate.sidebar && vm.oldTemplate.sidebar.length) {
                        vm.form.sidebar = vm.oldTemplate.sidebar;
                    }

                    if (vm.oldTemplate.sidebar_guarantee && vm.oldTemplate.sidebar_guarantee.length) {
                        vm.form.sidebar_guarantee = vm.oldTemplate.sidebar_guarantee;
                    }

                    if (vm.oldTemplate.sidebar_orders && vm.oldTemplate.sidebar_orders.length) {
                        vm.form.sidebar_orders = vm.oldTemplate.sidebar_orders;
                    }

                    if (vm.oldTemplate.sidebar_product && vm.oldTemplate.sidebar_product.length) {
                        vm.form.sidebar_product = vm.oldTemplate.sidebar_product;
                    }

                    if (vm.oldTemplate.sidebar_product_features && vm.oldTemplate.sidebar_product_features.length) {
                        vm.form.sidebar_product_features = vm.oldTemplate.sidebar_product_features;
                    }

                    if (vm.oldTemplate.sidebar_security && vm.oldTemplate.sidebar_security.length) {
                        vm.form.sidebar_security = vm.oldTemplate.sidebar_security;
                    }
                }
            },

            closeForm () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            accordion (e) {
                let el = e.currentTarget.parentElement;
                let allEl = document.querySelectorAll('.drag_list li');

                if (el.classList.contains('show')) {
                    el.classList.remove('show');
                } else {
                    for (let i = 0; i < allEl.length; i++) {
                        allEl[i].classList.remove('show');
                    }

                    el.classList.add('show');
                }
            },

            handleSubmit (form, { setFieldError }) {
                const vm = this;
                const params = vm.form;
                params.setFieldError = setFieldError;
                vm.saveProductTemplate(params).then((result) => {
                    if (result) {
                        vm.handleNextStep();
                        vm.refreshProduct();

                        // if (vm.template && vm.template.is_default == 1) {
                        //     setTimeout(() => {
                        //         vm.resetForm();
                        //     }, 100);
                        // }
                    }
                });
            },

            handleProductFeatureDelete (index) {
                const vm = this;

                vm.form.sidebar_product_features.splice(index, 1);
            },

            handleProductFeatureAdd () {
                const vm = this;
                const index = vm.form.sidebar_product_features.length + 1;

                vm.productFeatureEditedIndex = null;
                vm.form.sidebar_product_features.push({ index, title: `Amazing benefit ${index}` });
            },

            handleProductFeatureUpdate () {
                const vm = this;
                const params         = vm.form;
                params.setFieldError = vm.$refs['template-form'].setFieldError;

                vm.saveProductTemplate(params).then((result) => {
                    if (result) {
                        vm.productFeatureEditedIndex = null;
                        vm.refreshProduct();
                    }
                });
            },

            handleProofDelete (template, proof, index) {
                const vm = this;
                const options = Helper.swalConfirmOptions('Are you sure?', `This action is permanent. You will not be able to recover this proof.`, 'Delete');

                if (template && proof) {
                    options.preConfirm = function () {
                                        return vm.deleteProof({ template, proof }).then((result) => {
                                                   if (result)  {
                                                        vm.form.proofs.splice(index, 1);
                                                   }
                                                });
                                     }

                    Swal.fire(options);
                } else {
                    vm.form.proofs.splice(index, 1);
                }
            },

            handleProofAdd () {
                const vm = this;

                vm.form.proofs.push({
                    type: 1,
                    testimonial: 'Testimonial',
                    person_name: 'Person Name',
                    title_location: 'Title Location',
                    has_image: 0,
                    image: '',
                    has_star: 1,
                    star_color: '#ff98cf',
                    bg_color: '#FFFFFF',
                    text_color: '#5a5a5a',
                    has_shadow: 0,
                    shadow_color: '#888888',
                });
            },

            handleShowOrderBump (ev) {
                const vm = this;

                if (!vm.selectedProduct.has_bump && vm.form.has_order_bump) {
                    vm.form.has_order_bump = 0;
                    Swal.fire(Helper.swalWarningOptions('Opps!', `You don't have any order bump set, please set bump order.`));
                    ev.preventDefault();
                }
            },

            handleInfoShow (field) {
                const vm = this;

                if (!field.enabled && field.required) {
                    field.required = 0;
                }
            },

            handleInfoRequired (field) {
                const vm = this;

                if (field.required) {
                    field.enabled = 1;
                }
            },

            toggleSection (ev) {
                const vm = this;
                let parentElm = ev.currentTarget.closest('.section_content');

                if (parentElm.classList.contains('show')) {
                    parentElm.classList.remove('show');
                    vm.toggleAll = 1;
                } else {
                    parentElm.classList.add('show');
                    vm.toggleAll = 0;
                }
            },

            openAllSection () {
                const vm = this;
                let allParentElm = document.querySelectorAll('.section_content');

                for (let i = 0; i < allParentElm.length; i++) {
                    allParentElm[i].classList.add('show');
                }

                vm.toggleAll = 0;
            },

            closeAllSection () {
                const vm = this;
                let allParentElm = document.querySelectorAll('.section_content');

                for (let i = 0; i < allParentElm.length; i++) {
                    allParentElm[i].classList.remove('show');
                }

                vm.toggleAll = 1;
            },

            handleShowAcceptCoupon (ev) {
                const vm = this;

                if (!vm.selectedProduct.has_coupon && vm.form.accept_coupon) {
                    vm.form.accept_coupon = 0;
                    Swal.fire(Helper.swalWarningOptions('Opps!', `You don't have any coupon, please create coupon first.`));
                    ev.preventDefault();
                }
            },

            handleEditProductFeatureTitle (event, index) {
                const vm = this;

                if (vm.productFeatureEditedIndex != index) {
                    vm.productFeatureEditedIndex = index;

                    setTimeout(() => {
                        const el          = event.target;
                        const range       = document.createRange();
                        const selectPoint = document.getSelection();

                        range.setStart(el.childNodes[0], el.childNodes[0].length);
                        selectPoint.removeAllRanges();
                        selectPoint.addRange(range);
                    }, 1);
                }
            }
        },
    };
</script>

<style scoped>
    .drag_list {
        margin: 30px 0 30px 0;
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .drag_list li {
        background: #fff;
        border-radius: 8px;
        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.05);
        overflow: hidden;
    }

    .drag_list li h4 {
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        color: #121525;
        padding: 20px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: move;
    }

    .drag_list li h4 i {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid #e9e9e9;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        cursor: pointer;
        color: #7e7e7e;
        transition: all 0.3s ease-in-out;
    }

    .drag_list li.show h4 i {
        transform: rotate(-180deg);
    }

    .drag_list li .accordion_area {
        max-height: 0;
        padding: 0 30px;
        height: 100%;
        border-top: 1px solid #e9e9e9;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
    }

    .drag_list li.show .accordion_area {
        max-height: 100vh;
        height: 100%;
        overflow-y: scroll;
    }

    .drag_list li.show .accordion_area::-webkit-scrollbar {
        display: none;
    }

    .drag_list li.show .accordion_area .add_btn {
        margin: 0 0 0 auto;
        display: block;
    }

    .drag_list li.show .accordion_area .bullet_setting_wpr {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        padding-bottom: 30px;
    }

    .drag_list li.show .accordion_area .bullet_icons {
        display: flex;
        gap: 12px;
        flex-wrap: wrap;
        padding: 10px 0;
    }

    .drag_list li.show .accordion_area .bullet_icons .icon {
        width: 28px;
        height: 28px;
        border: 1px solid #e9e9e9;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
        font-size: 14px;
        color: #2c3e50;
        transform: scale(1);
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }

    .drag_list li.show .accordion_area .bullet_icons .icon.active {
        border-color: #afd0ff;
        color: #2f7eed;
        transform: scale(1.2);
    }

    .drag_list li.show .accordion_area .empty {
        padding: 20px 30px;
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        margin: 15px 0;
        font-size: 16px;
        font-weight: 300;
        line-height: 25px;
        color: #bebebe;
        text-align: center;
    }

    .custom_list li {
        border: 1px solid #eaeaea;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        padding: 10px 15px;
        margin-bottom: 15px;
    }

    .custom_list li h5 {
        display: flex;
        align-items: center;
        font-weight: 500;
    }

    .custom_list li h5 i {
        font-size: 15px;
    }

    .custom_list li h5 label {
        font-size: 13px;
        line-height: 18px;
        padding: 5px 13px;
        cursor: auto;
    }

    .custom_list li h5 span.save {
        padding: 3px 8px;
        background: #2f7eed;
        color: #fff;
        border-radius: 3px;
        font-size: 8px;
        line-height: 10px;
        text-transform: uppercase;
        cursor: pointer;
    }

    .custom_list li h5 .delete_btn {
        font-size: 16px;
        color: #eb1414;
        margin: 5px 0 5px 10px;
        cursor: pointer;
    }

    .proof_wpr {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        margin-bottom: 30px;
    }

    .proof_list {
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin: 15px 0 0 0;
    }

    .proof_card {
        border: 1px solid #e9e9e9;
    }

    .proof_card .sub_header {
        padding: 20px;
        border-bottom: 1px solid #e9e9e9;
    }

    .proof_card .details {
        padding: 15px 20px;
    }

    .container_type .type_list {
        display: flex;
        gap: 30px;
        margin-bottom: 40px;
    }

    .container_type .type_list li {
        flex: 1 1 auto;
        position: relative;
    }

    .container_type .type_list li .box {
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        border: 1px solid #e9e9e9;
        border-radius: 10px;
        background: #fff;
        filter: grayscale(1);
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }

    .container_type .type_list li img {
        height: 55px;
        width: auto;
    }

    .container_type .type_list li h4 {
        max-width: 100px;
        font-size: 15px;
        line-height: 22px;
        font-weight: 500;
        color: #121525;
    }

    .container_type .type_list li span {
        background: #2f7eed;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        font-size: 8px;
        color: #fff;
        position: absolute;
        right: 10px;
        top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        z-index: 2;
    }

    .container_type .type_list li input[type=radio]:checked~span {
        opacity: 1;
    }

    .container_type .type_list li input[type=radio]:checked~.box {
        border: 1px solid #bbd7fd;
        filter: grayscale(0);
    }

    .combo_fld {
        position: relative;
    }

    .combo_fld .field_wpr {
        padding-right: 50px;
    }

    .combo_fld .fld_clr :deep(.field_wpr) {
        position: absolute;
        right: 0;
        top: 0;
        border: 0;
    }

    .combo_fld .fld_clr :deep(.field_wpr input),
    .combo_fld .fld_clr :deep(.field_wpr .prefix-right) {
        display: none;
    }


    .reset-default {
        display: flex;
        justify-content: flex-end;
        font-size: 12px;
        line-height: 15px;
        color: #747474;
        font-weight: 400;
        cursor: pointer;
        position: relative;
    }



    .toggle_section {
        width: 25px;
        height: 25px;
        font-size: 16px;
        color: #999;
        cursor: pointer;
        margin-left: 15px;
    }
    .toggle_section i {
        transform: rotate(0);
        transition: all 0.3s ease-in-out;
    }

    .global_setting .tabs_content .section_content.show .toggle_section i{
        transform: rotate(-180deg);
    }

    .global_setting .tabs_content .section_content{
        position: relative;
    }
    .global_setting .tabs_content .section_content .section_header{
        margin: 10px 0;
    }
    .global_setting .tabs_content .section_content:after{
        content: '';
        position: absolute;
        left: 20px;
        top: 100%;
        right:20px;
        border-bottom: 1px solid #e9e9e9;
    }
    .global_setting .tabs_content .section_content.show:after, .global_setting .tabs_content .section_content:last-of-type:after, .global_setting .tabs_content .section_content.open_area:after{
        border: 0;
    }
    .global_setting .tabs_content .section_content .toggle_content{
        max-height: 0;
        overflow: hidden;
        animation: smoothSlidedown 0.3s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }
    .global_setting .tabs_content .section_content.show .toggle_content{
        max-height: 1000vh;
        animation: smoothSlideup 0.8s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }
    .global_setting .tabs_content .section_content.show .toggle_section i{
        transform: rotate(-180deg);
    }

    /* --- */

    .section_content .section_header .status{
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        margin: 0 0 0 auto;
        flex-grow: 0;
    }

    .section_content .section_header .switch_option{
        flex: 1 1 auto;
    }

    .global_setting .section_content .color_container {
        padding: 0px;
        border: 0px;
    }

    .global_setting .section_content.show .color_container {
        padding: 20px 35px;
        border: 1px solid #D6D6D6;
        border-radius: 6px;
        flex: 1;
    }
</style>
